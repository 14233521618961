<template>
  <div class="upload-container">
    <div class="queryItems">
      <el-button :disabled="uploadProcess" @click="submit" type="success" size="mini">
        {{ $t('upload.save') }}<i :class="uploadIcon"></i>
      </el-button>
      <el-upload
          action="#"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleFileSelect"
          accept=".xls,.xlsx"
      >
        <el-button
            style="margin-right: 10px; margin-left: 10px"
            type="info"
            size="mini"
        >
          {{ $t('upload.selectFile') }}<i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </el-upload>
      <el-button @click="exportExcel" type="primary" size="mini">
        {{ $t('upload.downloadTemplate') }}<i class="el-icon-download el-icon--right"></i>
      </el-button>
      <el-button @click="deleteTable" type="danger" size="mini">
        {{ $t('upload.delete') }}<i class="el-icon-delete el-icon--right"></i>
      </el-button>
    </div>
    <div class="data-body">
      <el-table
          v-loading="loading"
          :element-loading-text="$t('upload.loadingText')"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          ref="multipleTable"
          :height="tableHeight"
          :max-height="tableHeight"
          @selection-change="handleSelectionChange"
          size="mini"
          border
          :data="tableData"
          style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="ErrorMsg" show-overflow-tooltip width="150" :label="$t('upload.errorMsg')"></el-table-column>
        <el-table-column prop="MainBillCode" show-overflow-tooltip width="150" :label="$t('upload.mainBillCode')"></el-table-column>
        <el-table-column show-overflow-tooltip width="150" prop="CarrierBillCode" :label="$t('upload.carrierBillCode')"></el-table-column>
        <el-table-column prop="SubbillNumber" :label="$t('upload.subbillNumber')"></el-table-column>
        <el-table-column prop="ParcelCode" :label="$t('upload.parcelCode')"></el-table-column>
        <el-table-column prop="ClientRem" :label="$t('upload.clientRem')"></el-table-column>
        <el-table-column prop="Rem" :label="$t('upload.rem')"></el-table-column>
        <el-table-column prop="GoodsTypeTxt" :label="$t('upload.goodsTypeId')"></el-table-column>
        <el-table-column prop="TimerTxt" :label="$t('upload.timerId')"></el-table-column>
        <el-table-column prop="CarrierTxt" :label="$t('upload.carrierId')"></el-table-column>
        <el-table-column prop="GoodsCount" :label="$t('upload.goodsCount')"></el-table-column>
        <el-table-column prop="GoodsName" :label="$t('upload.goodsName')"></el-table-column>
        <el-table-column prop="GoodsName_En" :label="$t('upload.goodsNameEn')"></el-table-column>
        <el-table-column prop="DestinationTxt" :label="$t('upload.destinationId')"></el-table-column>
        <el-table-column prop="ProvinceTxt" :label="$t('upload.province')"></el-table-column>
        <el-table-column prop="CityTxt" :label="$t('upload.city')"></el-table-column>
        <el-table-column prop="AreaTxt" :label="$t('upload.area')"></el-table-column>
        <el-table-column prop="CollectionAmount" :label="$t('upload.collectionAmount')"></el-table-column>
        <el-table-column prop="InsuredAmount" :label="$t('upload.insuredAmount')"></el-table-column>
        <el-table-column prop="Weight" :label="$t('upload.weight')"></el-table-column>
        <el-table-column prop="IsTax" :label="$t('upload.isTax')"></el-table-column>
        <el-table-column prop="AgentPointTxt" :label="$t('upload.agentPointId')"></el-table-column>
        <el-table-column prop="IdCard" :label="$t('upload.idCard')" width="100"></el-table-column>
        <el-table-column prop="PostNo" :label="$t('upload.postNo')"></el-table-column>
        <el-table-column prop="ReceiveName" :label="$t('upload.receiveName')" width="100"></el-table-column>
        <el-table-column show-overflow-tooltip prop="ReceiveAddress" :label="$t('upload.receiveAddress')" width="150"></el-table-column>
        <el-table-column prop="ReceivePhoneNo" :label="$t('upload.receivePhoneNo')" width="100"></el-table-column>
        <el-table-column prop="ReceiveTel" :label="$t('upload.receiveTel')" width="100"></el-table-column>
        <el-table-column prop="Email" :label="$t('upload.email')"></el-table-column>
        <el-table-column prop="DeclareName" :label="$t('upload.declareName')" width="100"></el-table-column>
        <el-table-column prop="DeclareAddress" :label="$t('upload.declareAddress')" width="100"></el-table-column>
        <el-table-column prop="DeclareTel" :label="$t('upload.declareTel')" width="100"></el-table-column>
        <el-table-column prop="DeclarePhoneNo" :label="$t('upload.declarePhoneNo')" width="100"></el-table-column>
        <el-table-column prop="DeclareIdCard" :label="$t('upload.declareIdCard')"></el-table-column>
        <el-table-column prop="Declareprice" :label="$t('upload.declarePrice')"></el-table-column>
        <el-table-column prop="DeclareCount" :label="$t('upload.declareCount')"></el-table-column>
        <el-table-column prop="DeclareValue" :label="$t('upload.declareValue')"></el-table-column>
        <el-table-column prop="Length" :label="$t('upload.length')"></el-table-column>
        <el-table-column prop="Width" :label="$t('upload.width')"></el-table-column>
        <el-table-column prop="Height" :label="$t('upload.height')"></el-table-column>
        <el-table-column prop="CreateTime" :label="$t('upload.createTime')"></el-table-column>
      </el-table>
      <el-pagination
          class="mt20"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        :title="$t('upload.dialogTitle')"
        width="360px"
        :visible.sync="visible"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item :label="$t('upload.warehouseLabel')" prop="warehouse">
          <el-select v-model="ruleForm.warehouse">
            <el-option
                v-for="item in options"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="mt20" style="text-align: center">
          <el-button
              v-loading="uploadProcess"
              element-loading-spinner="el-icon-loading"
              type="primary"
              @click="submitForm"
          >
            {{ $t('upload.submitButton') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as xlsx from 'xlsx'
import {downloadTemplate, uploadOrder, validateTemplate} from "@/api/system";
export default {
  data() {
    return {
      listData: [],
      uploadProcess: false,
      visible: false,
      pageIndex: 1,
      loading: false,
      pageSize: 10,
      ruleForm: {},
      multipleSelection: [],
      options: [],
      rules: {
        warehouse: [
          { required: true, message: this.$t('upload.placeholderWarehouse'), trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.$store.dispatch('webSite/getWareHouse').then(data => {
      this.options = data
    })
  },
  computed: {
    total() {
      return this.listData.length
    },
    tableHeight() {
      return 'calc(100vh - 16rem - 100px)'
    },
    uploadIcon() {
      if (this.uploadProcess) {
        return 'el-icon-loading el-icon--left'
      } else {
        return 'el-icon-circle-check el-icon--right'
      }
    },
    tableData() {
      return this.listData.slice(((this.pageIndex - 1) * this.pageSize), this.pageIndex * this.pageSize)
    },
    deleteOrderCode() {
      return this.multipleSelection.map(item => { return item['MainBillCode'] }).join(',')
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportExcel() {
      try {
        downloadTemplate().then(response => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a');
          link.href = url
          link.setAttribute('download', this.$t('upload.templateName', [Date.now()]))
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          this.$message.success(this.$t('upload.downloadSuccess'))
        })
      } catch (e) {
        this.$message.warning(this.$t('upload.downloadError', [e]))
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {
            IsCheck: true,
            WarehouseId: this.ruleForm.warehouse,
            OrderList: this.listData
          }
          this.uploadProcess = true
          uploadOrder(data).then(response => {
            this.uploadProcess = false
            const { Code, Msg } = response
            if (Code === 200) {
              this.$message.success(this.$t('upload.exportSuccess'))
              this.visible = false
            } else {
              this.$message.warning(Msg)
            }
          }).catch((e) => {
            this.uploadProcess = false
            this.$message.warning(e)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submit() {
      if (this.listData.filter(item => { return item.ErrorMsg }).length) {
        this.$message.warning(this.$t('upload.validateError'))
        return
      }
      if (this.listData.length) {
        this.visible = true
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.pageIndex = val
    },
    excelToJson() {
      return this.listData.map(item => {
        return {
          MainBillCode: item['订单号'],
          CarrierBillCode: item['承运单号'],
          SubbillNumber: item['子单号'],
          ParcelCode: item['包裹单号'],
          ClientRem: item['客户备注'],
          Rem: item['订单备注'],
          GoodsTypeId: item['货物类型'],
          TimerId: item['时效'],
          CarrierId: item['承运商'],
          GoodsCount: item['货物数量'],
          GoodsName: item['品名'],
          GoodsName_En: item['英文品名'],
          DestinationId: item['目的地'],
          Province: item['省份'],
          City: item['城市'],
          Area: item['区域'],
          CollectionAmount: item['代收货款'],
          InsuredAmount: item['保价金额'],
          Weight: item['实际重量'],
          IsTax: item['是否包税(1包税,其他不包税)'],
          AgentPointId: item['自提点'],
          IdCard: item['身份证号码'],
          PostNo: item['邮政编码'],
          ReceiveName: item['收件人名称'],
          ReceiveAddress: item['收件人地址'],
          ReceivePhoneNo: item['收件人手机'],
          ReceiveTel: item['收件人电话'],
          Email: item['邮箱'],
          DeclareName: item['申报人名称'],
          DeclareAddress: item['申报人地址'],
          DeclareTel: item['申报人电话'],
          DeclarePhoneNo: item['申报人手机'],
          DeclareIdCard: item['申报统编'],
          Declareprice: item['申报单价'],
          DeclareCount: item['申报数量'],
          DeclareValue: item['申报价值'],
          CreateTime: item['下单时间'],
          Length: item['长'],
          Width: item['宽'],
          Height: item['高']
        }
      })
    },
    deleteTable() {
      this.$confirm(this.$t('upload.message', [this.deleteOrderCode]), this.$t('upload.prompt'), {
        confirmButtonText: this.$t('upload.confirm'),
        cancelButtonText: this.$t('upload.cancel'),
        type: 'warning'
      }).then(() => {
        this.listData = this.listData.filter(item => !this.deleteOrderCode.includes(item.MainBillCode));
        this.$message.success(this.$t('upload.deleteSuccess'))
      })
    },
    afterProcessData() {
      const data = {
        IsCheck: false,
        orderImportModels: this.excelToJson()
      }
      this.loading = true
      validateTemplate(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data
        } else {
          this.$message.warning(Msg)
        }
      }).catch((e) => {
        this.loading = false
        this.$message.warning(e)
      })
    },
    handleFileSelect(event) {
      this.listData = []
      const file = event.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
        let headers = jsonData[0];
        jsonData.slice(1).forEach(row => {
          const rowData = row.reduce((accumulator, item, index) => {
            let column = headers[index]
            accumulator[column] = item;
            return accumulator
          }, {})
          this.listData.push(rowData)
        })
        this.afterProcessData();
      };
      reader.readAsArrayBuffer(file);
    },
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 10px;
}
.upload-container {
  padding: 2rem;
  .queryItems {
    display: flex;
    margin-bottom: 2rem;
  }
}
</style>